// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {isFeatureEnabled} from '@github-ui/feature-flags'
import {verifiedFetchJSON} from '@github-ui/verified-fetch'

const homepageTestFeatureFlagEnabled = isFeatureEnabled('ab_test_homepage_what_is_copilot_video')

// On document ready, fetch the experiment variants
document.addEventListener('DOMContentLoaded', () => {
  fetchVariants()
})

// Fetches the experiment variants / displays the video
const fetchVariants = async () => {
  const video = document.querySelector('.experimental-video') as HTMLElement

  try {
    if (homepageTestFeatureFlagEnabled) {
      const response = await verifiedFetchJSON('/exp/webex/variants/homepage_video_popup')
      const experiment = await response.json()

      if (experiment['value'] === 'display_video_popup') {
        video.classList.remove('hidden')
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching experiments:', error)
  }
}

// Closes the video thumbnail popup with animation
on('click', '.js-close-video', function (e) {
  e.preventDefault()

  const video = document.querySelector('.experimental-video') as HTMLElement
  video.classList.add('hx_anim-fade-out')
})
